// Google Map
export const formatGoogleMapData = (data) => {
  const { address_components, geometry } = data;
  let formattedData = {};
  const ac = address_components;
  const premise = findAddressComponent(ac, 'premise');
  const street_number = findAddressComponent(ac, 'street_number');
  const route = findAddressComponent(ac, 'route');
  let city = findAddressComponent(ac, 'locality');
  let sublocality_level_1 = findAddressComponent(ac, 'sublocality_level_1');
  const postal_town = findAddressComponent(ac, 'postal_town');
  const state = findAddressComponent(ac, 'administrative_area_level_1');
  const country = findAddressComponent(ac, 'country');
  const postal_code = findAddressComponent(ac, 'postal_code');

  const addressLine1 = [street_number, premise, route];

  if (!city) city = postal_town || sublocality_level_1;

  formattedData.line1 = addressLine1
    .filter((e) => e)
    .map((e) => e.long_name)
    .join(' ');
  formattedData.line2 = null;
  formattedData.city = city ? city.long_name : null;
  formattedData.postalCode = postal_code ? postal_code.long_name : null;
  formattedData.state = state ? state.short_name : null;

  formattedData.country = country ? country.short_name : null;

  formattedData.latitude = geometry.location.lat();
  formattedData.longitude = geometry.location.lng();
  return formattedData;
};

export const findAddressComponent = (address_components, target) => {
  return address_components.find((el) => {
    return el.types.includes(target);
  });
};
